import { createUserWithEmailAndPassword, deleteUser } from "firebase/auth";
import { useState } from "react";
import { firebaseAuth } from "../firebaseAuth";
import Notification from "../components/Notification";

type SignUpOfficeForm = {
  email: string;
  password: string;
  passwordConfirmation: string;
  officeName: string;
  postalCode: string;
  phoneNumber: string;
  officeAddress: string;
  staffName: string;
  registrationCode: string;
};
export default function SignUpOffice() {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const [form, setForm] = useState<SignUpOfficeForm>({
    email: "",
    password: "",
    passwordConfirmation: "",
    officeName: "",
    postalCode: "",
    phoneNumber: "",
    officeAddress: "",
    staffName: "",
    registrationCode: "",
  });
  const [openNotification, setOpenNotification] = useState(false);
  const [httpResponseResult, setHttpResponseResult] = useState({
    success: "",
    error: "",
  });

  const signUp = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("password", form.password);
    formData.append("email", form.email);
    formData.append("officeName", form.officeName);
    formData.append("postalCode", form.postalCode);
    formData.append("phoneNumber", form.phoneNumber);
    formData.append("officeAddress", form.officeAddress);
    formData.append("staffName", form.staffName);
    formData.append("registrationCode", form.registrationCode);
    createUserWithEmailAndPassword(firebaseAuth, form.email, form.password)
      .then((userCredential) => {
        userCredential.user
          .getIdToken(true)
          .then((idToken) => {
            fetch(
              `${process.env.REACT_APP_BACKEND_URL}/internal/signup-office`,
              {
                headers: { Authorization: idToken },
                method: "POST",
                body: formData,
                credentials: "include",
              }
            ).then((response) => {
              if (response.ok) {
                setHttpResponseResult({
                  success: "新規登録完了しました",
                  error: "",
                });
                setOpenNotification(true);
              } else {
                deleteUser(userCredential.user).finally(() => {
                  setHttpResponseResult({
                    error: "事業所作成に失敗しました。",
                    success: "",
                  });
                  setOpenNotification(true);
                  return;
                });
              }
            });
          })
          .catch((e) => {
            deleteUser(userCredential.user).finally(() => {
              setHttpResponseResult({
                error:
                  "予期せぬエラーが発生しました。カスタマーサポートまでご連絡ください。",
                success: "",
              });
              setOpenNotification(true);
              return;
            });
          });
      })
      .catch((error) => {
        const message = structuredClone(httpResponseResult);
        message.error = error.message;
        setHttpResponseResult(message);
        setOpenNotification(true);
        return;
      });
  };

  return (
    <>
      {httpResponseResult.error && (
        <Notification
          alertMessage={httpResponseResult.error}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
        />
      )}
      {httpResponseResult.success && (
        <Notification
          infoMessage={httpResponseResult.success}
          openNotification={openNotification}
          setOpenNotification={setOpenNotification}
        />
      )}
      <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
            新規事業所登録
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <form className="space-y-6" onSubmit={signUp}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900"
              >
                メールアドレス
              </label>
              <div className="mt-2">
                <input
                  id="email"
                  name="email"
                  type="email"
                  required
                  autoComplete="email"
                  value={form.email}
                  onChange={(e) => {
                    setForm(Object.assign({}, form, { email: e.target.value }));
                  }}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  パスワード
                </label>
                <div className="text-sm">
                  <a
                    href="#"
                    className="font-semibold text-indigo-600 hover:text-indigo-500"
                  >
                    パスワードを忘れましたか？
                  </a>
                </div>
              </div>
              <div className="mt-2 flex items-center">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  minLength={8}
                  required
                  value={form.password}
                  onChange={(e) => {
                    setForm(
                      Object.assign({}, form, { password: e.target.value })
                    );
                  }}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <button
                  className="ml-1"
                  type="button"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  {showPassword && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                      />
                    </svg>
                  )}
                  {!showPassword && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  パスワード確認
                </label>
              </div>
              <div className="mt-2 flex items-center">
                <input
                  id="password-confirmation"
                  name="password-confirmation"
                  type={showPasswordConfirmation ? "text" : "password"}
                  required
                  value={form.passwordConfirmation}
                  onChange={(e) => {
                    setForm(
                      Object.assign({}, form, {
                        passwordConfirmation: e.target.value,
                      })
                    );
                  }}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
                <button
                  className="ml-1"
                  type="button"
                  onClick={() => {
                    setShowPasswordConfirmation(!showPasswordConfirmation);
                  }}
                >
                  {showPasswordConfirmation && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.98 8.223A10.477 10.477 0 0 0 1.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.451 10.451 0 0 1 12 4.5c4.756 0 8.773 3.162 10.065 7.498a10.522 10.522 0 0 1-4.293 5.774M6.228 6.228 3 3m3.228 3.228 3.65 3.65m7.894 7.894L21 21m-3.228-3.228-3.65-3.65m0 0a3 3 0 1 0-4.243-4.243m4.242 4.242L9.88 9.88"
                      />
                    </svg>
                  )}
                  {!showPasswordConfirmation && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                      />
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>

            <div className="flex items-center space-x-1">
              <div className="flex-1 border-b border-gray-200"></div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  法人名
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="office-name"
                  name="office-name"
                  type="text"
                  required
                  value={form.officeName}
                  onChange={(e) => {
                    setForm(
                      Object.assign({}, form, {
                        officeName: e.target.value,
                      })
                    );
                  }}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  郵便番号
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="postal-code"
                  name="postal-code"
                  type="number"
                  required
                  value={form.postalCode}
                  onChange={(e) => {
                    setForm(
                      Object.assign({}, form, {
                        postalCode: e.target.value,
                      })
                    );
                  }}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  住所
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="office-address"
                  name="office-address"
                  type="text"
                  required
                  value={form.officeAddress}
                  onChange={(e) => {
                    setForm(
                      Object.assign({}, form, {
                        officeAddress: e.target.value,
                      })
                    );
                  }}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  電話番号
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="phone-number"
                  name="phone-number"
                  type="number"
                  required
                  value={form.phoneNumber}
                  onChange={(e) => {
                    setForm(
                      Object.assign({}, form, {
                        phoneNumber: e.target.value,
                      })
                    );
                  }}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="staff-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  担当者氏名
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="staff-name"
                  name="staff-name"
                  type="text"
                  required
                  value={form.staffName}
                  onChange={(e) => {
                    setForm(
                      Object.assign({}, form, {
                        staffName: e.target.value,
                      })
                    );
                  }}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="staff-name"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  新規登録用コード
                </label>
              </div>
              <div className="mt-2">
                <input
                  id="registration-code"
                  name="registration-code"
                  type="text"
                  required
                  value={form.registrationCode}
                  onChange={(e) => {
                    setForm(
                      Object.assign({}, form, {
                        registrationCode: e.target.value,
                      })
                    );
                  }}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                登録
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
