import { useEffect, useState } from "react";
import "./App.css";
import NoAuthLayout from "./components/NoAuthLayout";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignUpOffice from "./Pages/SignUpOffice";
import AuthLayout from "./components/AuthLayout";
import Dashboard from "./Pages/Dashboard";
import IndividualCase from "./Pages/IndividualCase";
import { firebaseAuth } from "./firebaseAuth";
import { User } from "firebase/auth";
import SignUpUser from "./Pages/SignUpUser";
import SignInUser from "./Pages/SignInUser";
import SignInOffice from "./Pages/SignInOffice";

function App() {
  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);
  useEffect(() => {
    if (currentUser) {
      return;
    }
    firebaseAuth.onAuthStateChanged(function (user) {
      if (user) {
        setCurrentUser(user);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<NoAuthLayout />}>
          <Route path="/signup-office" element={<SignUpOffice />} />
          <Route path="/signup-user" element={<SignUpUser />} />
          <Route path="/signin-user" element={<SignInUser />} />
          <Route path="/signin-office" element={<SignInOffice />} />
        </Route>
        <Route element={<AuthLayout />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/case/:id" element={<IndividualCase />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
