export default function Footer() {
  return (
    <footer className="bg-white border-y-2 text-xs">
      <div className="max-w-screen-xl py-1 mx-auto md:py-3 md:px-6 p-2">
        <div>
          <div className="flex flex-wrap md:w-4/5 m-auto">
            <div className="w-1/2 lg:w-1/4 mb-4">
              <a href="#" className="hover:underline">
                会社情報
              </a>
            </div>
            <div className="w-1/2 lg:w-1/4 mb-4">
              <a href="/privacy-policy" className="hover:underline">
                プライバシーポリシー
              </a>
            </div>
            <div className="w-1/2 lg:w-1/4 mb-4">
              <a href="#" className="hover:underline">
                利用規約
              </a>
            </div>
            <div className="w-1/2 lg:w-1/4 mb-4">
              <a href="#" className="hover:underline">
                特商法に基づく表記
              </a>
            </div>
          </div>
          <p className="block text-sm text-center text-gray-500 mt-3">
            Copyright © TORUTE. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
}
